import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@hq-core/models/app-settings';
import { DataResource } from '@hq-custom-types';
import { ParameterBuilder, SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { Observable } from 'rxjs';
import { ProductTrend, ProductTrendApiResponse, ProductTrendParameters } from '../models/product-trend';

@Injectable({
    providedIn: 'root'
})
export class ProductTrendResource implements DataResource<ProductTrend> {
    private baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.report}`;
    }

    getAll(searchState: SearchState, productTrendParameters: ProductTrendParameters): Observable<ProductTrendApiResponse> {
        const url = `${this.baseUrl}/product-trends`;
        let params = new HttpParams({
            fromObject: {
                pageIndex: (searchState.pageIndex || 0).toString(),
                pageSize: (searchState.pageSize || 25).toString(),
                sortBy: searchState.sortBy || '',
                sortDirection: searchState.sortDirection || 'asc',
                search: searchState.search || ''
            }
        });
        params = ParameterBuilder.buildArrayParameter(productTrendParameters.brandIds, 'brandIds', params);
        params = ParameterBuilder.buildArrayParameter(productTrendParameters.states, 'states', params);

        return this.http.get<ProductTrendApiResponse>(url, { params });
    }
}
