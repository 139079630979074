import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter, inject,
    Input, OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TrackByFunction,
    ViewChild,
    ViewChildren,
    QueryList
} from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { MatMenuTrigger } from '@angular/material/menu';
import { BrokerStoreFilters } from '@hq-app/broker-stores/models/broker-store-response';
import { TransactionStaticFilters } from '@hq-app/monitoring/models/transaction';
import { OrderFilters, ProductFilters } from '@hq-app/ordering/models/order-filter';
import { ProductSearchFilters } from '@hq-app/search/models/product-search';
import { FilterChangeEvent, FilterGroup, SearchFilter } from '@hq-core/models/search-filter';
import { SortOption, SortOptionChangeEvent } from '@hq-shared/models/sort';
import { Subject } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

interface FilterState {
    searchText: { [groupId: string]: string };
    filteredOptions: { [groupId: string]: Array<SearchFilter> };
}

@Component({
    selector: 'hq-filter-menu',
    templateUrl: './filter-menu.component.html',
    styleUrls: ['./filter-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterMenuComponent implements OnInit, OnChanges {
    private cdRef = inject(ChangeDetectorRef);

    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
    @ViewChildren(CdkVirtualScrollViewport) virtualScrollViewports: QueryList<CdkVirtualScrollViewport>;
    @Output() clearFilters = new EventEmitter<void>();
    @Output() changeFilter = new EventEmitter<FilterChangeEvent>();
    @Output() changeSortOption = new EventEmitter<SortOptionChangeEvent>();
    @Output() closed = new EventEmitter<void>();

    @Input() sortOptions: Array<SortOption>;
    @Input() defaultSortType: string;
    @Input() filterGroups: Array<FilterGroup>;
    @Input() openFilterGroup: string;
    @Input() filters: OrderFilters
        | ProductFilters
        | ProductSearchFilters
        | TransactionStaticFilters
        | BrokerStoreFilters;
    @Input() showApplyButton = false;

    clearSort = new Subject<boolean>();
    filterTrackBy: TrackByFunction<SearchFilter> = (index: number, filter: SearchFilter) => filter.key;

    private state: FilterState = {
        searchText: {},
        filteredOptions: {}
    };

    get searchText(): { [groupId: string]: string } {
        return this.state.searchText;
    }

    ngOnInit(): void {
        this.state = this.initializeFilterState(this.filterGroups, this.filters);
        this.setupMenuOpenListener();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filters) {
            const newFilteredOptions = { ...this.state.filteredOptions };

            this.filterGroups.forEach(group => {
                const currentSearchText = this.state.searchText[group.id] || '';
                const options = this.filters[group.id] || [];
                newFilteredOptions[group.id] = this.applySearch(options, currentSearchText);
            });

            this.updateState({ filteredOptions: newFilteredOptions });
        }
    }

    onClearFilters(): void {
        this.clearSort.next(true);
        this.clearFilters.emit();

        // Reset search text and reset filtered options to show all options
        const newSearchText = Object.keys(this.state.searchText).reduce(
            (acc, groupId) => ({ ...acc, [groupId]: '' }),
            {}
        );

        const newFilteredOptions = Object.keys(this.state.filteredOptions).reduce(
            (acc, groupId) => ({ ...acc, [groupId]: this.filters[groupId] || [] }),
            {}
        );

        this.updateState({
            searchText: newSearchText,
            filteredOptions: newFilteredOptions
        });
    }

    onChangeSortOption(event: SortOptionChangeEvent): void {
        this.changeSortOption.emit(event);
    }

    onChangeFilter(event: MatSelectionListChange, groupId: string): void {
        const changedFilter = event.options[0];
        const filterChangeEvent = new FilterChangeEvent({
            isSelected: changedFilter.selected,
            filterId: changedFilter.value,
            groupId
        });

        // Update the selection state in the original filters
        const originalFilter = this.filters[groupId]?.find(f => f.key === changedFilter.value);
        if (originalFilter) {
            originalFilter.isSelected = changedFilter.selected;
        }

        // Update filtered options to reflect the change
        const newFilteredOptions = { ...this.state.filteredOptions };
        newFilteredOptions[groupId] = this.state.filteredOptions[groupId].map(filter =>
            filter.key === changedFilter.value
                ? { ...filter, isSelected: changedFilter.selected }
                : filter
        );

        this.updateState({ filteredOptions: newFilteredOptions });
        this.changeFilter.emit(filterChangeEvent);
    }

    onClose(): void {
        // Only reinitialize the search text, preserve the filter selections
        const newState = {
            searchText: this.filterGroups.reduce((acc, group) => ({ ...acc, [group.id]: '' }), {}),
            filteredOptions: this.state.filteredOptions
        };

        this.updateState(newState);
        this.closed.emit();
        this.menuTrigger.closeMenu();
    }

    getFilterGroupScrollHeight(group: Array<any>): number {
        // should max out at 6 items, but be dynamic up to that point. Each item is 48px tall.
        return (group.length > 6 ? 6 : group.length) * 48;
    }

    onFilterSearch(searchText: string, groupId: string): void {
        const options = this.filters[groupId] || [];
        const newFilteredOptions = {
            ...this.state.filteredOptions,
            [groupId]: this.applySearch(options, searchText)
        };

        this.updateState({
            searchText: { ...this.state.searchText, [groupId]: searchText },
            filteredOptions: newFilteredOptions
        });
    }

    clearFilterSearch(groupId: string): void {
        if (this.searchInput) {
            this.searchInput.nativeElement.value = '';
        }

        // Preserve the current filter options when clearing search
        const currentOptions = this.filters[groupId] || [];
        const newFilteredOptions = {
            ...this.state.filteredOptions,
            [groupId]: currentOptions
        };

        this.updateState({
            searchText: { ...this.state.searchText, [groupId]: '' },
            filteredOptions: newFilteredOptions
        });
    }

    getFilteredOptions(groupId: string): Array<SearchFilter> {
        return this.state.filteredOptions[groupId] || [];
    }

    getSelectedFilters(groupId: string): Array<SearchFilter> {
        return this.filters[groupId]?.filter(filter => filter.isSelected) || [];
    }

    clearGroupSelections(groupId: string): void {
        // Get all selected filters before clearing
        const selectedFilters = this.getSelectedFilters(groupId);

        // Update the original filters if they exist
        this.filters[groupId]?.forEach(filter => {
            filter.isSelected = false;
        });

        // Update filtered options to reflect the changes
        const newFilteredOptions = { ...this.state.filteredOptions };
        if (this.state.filteredOptions[groupId]) {
            newFilteredOptions[groupId] = this.state.filteredOptions[groupId].map(filter => ({
                ...filter,
                isSelected: false
            }));

            this.updateState({ filteredOptions: newFilteredOptions });

            // Emit change events for each filter that was selected
            selectedFilters.forEach(filter => {
                const filterChangeEvent = new FilterChangeEvent({
                    isSelected: false,
                    filterId: filter.key,
                    groupId
                });
                this.changeFilter.emit(filterChangeEvent);
            });
        }

        // Force change detection
        this.cdRef.detectChanges();
    }

    private applySearch(
        options: Array<SearchFilter>,
        searchText: string,
    ): Array<SearchFilter> {
        const normalizedSearch = searchText.toLowerCase().trim();
        if (!normalizedSearch) {
            return options;
        }

        return options.filter(option =>
            option.description.toLowerCase().includes(normalizedSearch)
        );
    }

    private initializeFilterState(
        groups: Array<FilterGroup>,
        filters: any
    ): FilterState {
        return {
            searchText: groups.reduce((acc, group) => ({ ...acc, [group.id]: '' }), {}),
            filteredOptions: groups.reduce((acc, group) => ({
                ...acc,
                [group.id]: filters[group.id] || []
            }), {})
        };
    }

    private updateState(newState: Partial<FilterState>): void {
        this.state = { ...this.state, ...newState };
        this.cdRef.detectChanges();
    }

    private setupMenuOpenListener(): void {
        this.menuTrigger?.menuOpened.subscribe(() => {
            setTimeout(() => {
                this.virtualScrollViewports?.forEach(viewport => {
                    viewport.scrollToIndex(0);
                    viewport.checkViewportSize();
                });
            });
        });
    }
}
