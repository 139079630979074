// Url fragments should be lowercase, dash-separated words
export const routeParts = {
    accounts: 'accounts',
    advertisers: 'advertisers',
    advertising: 'advertising',
    analytics: 'analytics',
    applicationModules: 'application-modules',
    assign: 'assign',
    backOneLevel: '../',
    backTwoLevels: '../../',
    brokerCcOrders: 'vendor-orders',
    brokerDataManagement: 'data-management',
    brokerReporting: 'services',
    brokers: 'brokers',
    brokerStores: 'stores',
    buyiqFeatureFlags: 'buyiq-feature-flags',
    buyiqSettings: 'buyiq-settings',
    catalogExport: 'catalog-export',
    catalogs: 'catalogs',
    chains: 'chains',
    children: 'children',
    contacts: 'contacts',
    create: 'create',
    creditAdjustments: 'credit-adjustments',
    dashboard: 'dashboard',
    detaSynq: 'deta-synq',
    discounts: 'discounts',
    documentAlerting: 'document-alerting',
    edit: 'edit',
    extendedStoreInformation: 'extended-store-information',
    extendedSupplierInformation: 'extended-supplier-information',
    featureFlags: 'feature-flags',
    focus: 'focus-promotions',
    focusOrder: 'focus-order',
    freeTrial: 'free-trial',
    freeTrialThankYou: 'thank-you',
    terms: 'terms',
    help: 'help',
    helpResources: 'help-resources',
    import: 'import-orders',
    internalVendorMapping: 'internal-vendor-mapping',
    assignedBrands: 'assigned-brands',
    inventory: 'inventory',
    invoice: 'invoice',
    acknowledgement: 'acknowledgement',
    localVendors: 'local-vendors',
    login: 'login',
    logout: 'logout',
    main: '',
    monitoring: 'monitoring',
    newProducts: 'new-products',
    orderCopyConfig: 'order-copy-config',
    orders: 'orders',
    password: 'password',
    preOrder: 'pre-order',
    processedDocumentsSummary: 'processed-documents-summary',
    products: 'products',
    productSummary: 'product-summary',
    promotions: 'promotions',
    promotionsManagement: 'promotions-management',
    purchaseOrder: 'purchase-order',
    purchaseOrderChanges: 'purchase-order-changes',
    regions: 'regions',
    reports: 'reports',
    reportBrandOrderHistory: 'brand-order-history',
    reportItemOrderHistoryUpc: 'item-order-history-upc',
    reportItemOrderHistorySupplier: 'item-order-history-supplier',
    reportItemOrderHistoryPo: 'item-order-history-po',
    reportSupplierOrderHistory: 'supplier-order-history',
    resetPassword: 'reset-password',
    samples: 'samples',
    savedItems: 'saved-items',
    search: 'search',
    settings: 'settings',
    shelfTags: 'shelf-tags',
    shipping: 'shipping',
    storeManagementTools: 'store-management-tools',
    stores: 'stores',
    subscriptions: 'subscriptions',
    suppliers: 'suppliers',
    systemMessages: 'system-messages',
    territories: 'territories',
    transactions: 'transactions',
    trendingProducts: 'trending-products',
    turnoverOrders: 'turnover-orders',
    unauthorized: 'unauthorized',
    userManagement: 'user-management',
    users: 'users',
    vendorDiscounts: 'vendor-discounts',
    vendorMapping: 'vendor-mapping',
    vendorRankings: 'vendor-rankings',
    vendors: 'vendors',
    enterpriseSystems: 'enterprise-systems',
    trainingResources: 'training-resources',
    trainingMaterials: 'training-materials',
    orderPriority: 'order-priority',
    headlineManagement: 'headline-management',
    supplierGeniusTraining: 'supplier-genius-training',
    detaSynqDepartments: 'deta-synq-departments',
    catalogProducts: 'catalog',
    detaSynqStoreMappings: 'deta-synq-store-mapping',
};

export const pageTitles = {
    advertising: 'Advertising',
    analytics: 'Analytics',
    brokerCcOrders: 'Vendor Orders',
    brokerDataManagement: 'Data Management',
    brokerStores: 'Stores',
    catalogManagement: 'Catalog Management',
    dashboard: 'Dashboard',
    detaSynq: 'Order+ Status',
    freeTrial: 'Free Trial',
    terms: 'Terms and Conditions',
    help: 'Help',
    inventory: 'Inventory',
    internalVendorMapping: 'Supplier Mapping',
    localVendors: 'Local Vendors',
    login: 'Login',
    logout: 'Logout',
    monitoring: 'Monitoring',
    myGeniusCentral: 'Go to MyGeniusCentral',
    notFound: 'Not Found',
    ordering: 'Orders',
    productSearch: 'Search',
    promotions: 'Promotions',
    promotionsManagement: 'Promotions',
    reports: 'Reports',
    savedItems: 'My Favorite Items',
    settings: 'Settings',
    shelfTags: 'Shelf Tags',
    storeManagementTools: 'Store Management Tools',
    supplierGenius: 'Go to SupplierGenius',
    supplierManagement: 'Supplier Management',
    systemMessages: 'System Messages',
    unauthorized: 'Unauthorized',
    userManagement: 'User Management',
    vendorMapping: 'Supplier Mapping',
    enterpriseSystems: 'Enterprise Systems',
    trainingResources: 'Training Resources',
    supplierGeniusTraining: 'Supplier Training Materials',
    detaSynqDepartments: 'Departments'
};
