import { StateId } from '@hq-app/promotions-management/models/targeting';
import { SearchFilter } from '@hq-core/models/search-filter';
import { ApiCollectionResponse } from '@cia-front-end-apps/shared/api-interaction';
import { DropdownOption } from '@hq-shared/models/dropdown-option';

export enum ProductTrendRegion {
    Southeast = 'SE',
    Northeast = 'NE',
    Midwest = 'MW',
    Northwest = 'NW',
    Southwest = 'SW'
}

export class ProductTrend {
    scope: string;
    product: ProductTrendProduct;
    brand: ProductTrendBrand;
    category: ProductTrendBrand;
    percent30Days: number;
    percent60Days: number;
    percent90Days: number;

    constructor(options?: Partial<ProductTrend>) {
        Object.assign(this, options);
    }
}

export class ProductTrendBrand {
    id: number;
    name: string;

    constructor(options?: Partial<ProductTrendBrand>) {
        Object.assign(this, options);
    }
}

export class ProductTrendCategory {
    id: number;
    name: string;

    constructor(options?: Partial<ProductTrendCategory>) {
        Object.assign(this, options);
    }
}

export class ProductTrendProduct {
    upc: string;
    name: string;

    constructor(options?: Partial<ProductTrendProduct>) {
        Object.assign(this, options);
    }
}

export class ProductTrendFilters {
    brands = new Array<SearchFilter>();

    constructor(options?: Partial<ProductTrendFilters>) {
        Object.assign(this, options);
    }
}

export class ProductTrendApiResponse extends ApiCollectionResponse<ProductTrend> {
    filters = new ProductTrendFilters();

    constructor(options?: Partial<ProductTrendApiResponse>) {
        super();
        Object.assign(this, options);
    }
}

export class ProductTrendParameters {
    states: Array<StateId>;
    brandIds: Array<number>;

    constructor(options?: Partial<ProductTrendParameters>) {
        Object.assign(this, options);
    }
}
