<mat-form-field [floatLabel]="formArray.length > 0 ? 'always' : 'auto'">
    <mat-label>{{ label }}</mat-label>
    <div *ngIf="formArray.length > 0">
        <mat-chip-set>
            <mat-chip *ngFor="let control of formArray.controls.slice(0, 3); let index = index"
                      [removable]="true"
                      color="accent"
                      (removed)="removeChip(index, control.value)">
                {{ control.value.displayName }}
                <mat-icon matChipRemove
                          class="mat-chip-remove-icon"
                          (click)="$event.stopPropagation()">
                    cancel
                </mat-icon>
            </mat-chip>
            <mat-chip *ngIf="formArray.length > 3"
                      (click)="$event.stopPropagation(); $event.preventDefault()"
                      color="accent"
                      #menuTrigger="matMenuTrigger"
                      [matMenuTriggerFor]="menu"
                      class="more-chip">
                <span>+{{ formArray.length - 3 }} more</span>
                <mat-icon matChipTrailingIcon
                          class="menu-icon">
                    more_vert
                </mat-icon>
            </mat-chip>
        </mat-chip-set>
        <mat-menu #menu="matMenu">
            <div *ngFor="let control of formArray.controls.slice(3); let index = index"
                 mat-menu-item>
                <mat-checkbox color="primary"
                              [checked]="true"
                              (click)="$event.stopPropagation(); $event.preventDefault()"
                              (change)="onRemoveFromMenu(control.value)">
                </mat-checkbox>
                {{ control.value.displayName }}
            </div>
        </mat-menu>
    </div>
    <mat-select #select="matSelect"
                (selectionChange)="onSelectionChange($event)"
                [formControl]="selectControl">
        <mat-option class="search-option autocomplete-select-option">
            <div class="mat-select-search-inner-row">
                <input class="mat-select-search-input"
                       [formControl]="formControl"
                       [placeholder]="placeholder"
                       (keydown.space)="$event.stopPropagation()"
                       (click)="$event.stopPropagation()"
                       matInput
                />

                <mat-spinner *ngIf="searching"
                             class="mat-select-search-spinner"
                             diameter="16"></mat-spinner>

                <button *ngIf="formControl.value && !searching"
                        mat-icon-button
                        aria-label="Clear"
                        (click)="clearSearch($event)"
                        class="mat-select-search-clear">
                    <mat-icon>
                        close
                    </mat-icon>
                </button>
            </div>

        </mat-option>
        <mat-divider></mat-divider>
        <ng-container *ngIf="formControl.value">
            <mat-option *ngFor="let option of displayedOptions"
                        [value]="option"
                        class="autocomplete-select-option">
                <div (click)="optionClicked($event, option)">
                    <mat-checkbox color="primary"
                                  [checked]="isChecked(option)"
                                  (change)="toggleSelection(option)"
                                  (click)="$event.stopPropagation()">
                    </mat-checkbox>
                    {{ option.displayName }}
                </div>
            </mat-option>
            <mat-option *ngIf="hasMoreItems"
                        [disabled]="true"
                        class="more-items-option">
                <div class="more-items-text">
                    {{ getRemainingItemsCount() }} more items available. Type to continue filtering.
                </div>
            </mat-option>
        </ng-container>
        <ng-container *ngIf="!formControl.value">
            <mat-optgroup label="{{formArray.length}} Selected"
                          class="selected-items">
                <button *ngIf="formArray.length > 0"
                        (click)="clearSelections($event)"
                        mat-button
                        color="accent"
                        class="clear-selections-button">
                    Clear all
                </button>
                <mat-option *ngFor="let control of formArray.controls"
                            [value]="control.value"
                            class="autocomplete-select-option">
                    <div (click)="optionClicked($event, control.value)"
                         class="autocomplete-select-option-inner">
                        <mat-checkbox color="primary"
                                      [checked]="true">
                        </mat-checkbox>
                        {{ control.value.displayName }}
                    </div>
                </mat-option>
            </mat-optgroup>
            <mat-optgroup label="Available">
                <mat-option *ngFor="let option of displayedOptions"
                            [value]="option"
                            class="autocomplete-select-option">
                    <div (click)="optionClicked($event, option)"
                         class="autocomplete-select-option-inner">
                        <mat-checkbox color="primary"
                                      [checked]="isChecked(option)">
                        </mat-checkbox>
                        {{ option.displayName }}
                    </div>
                </mat-option>
                <mat-option *ngIf="hasMoreItems"
                            [disabled]="true"
                            class="more-items-option">
                    <div class="more-items-text">
                        {{ getRemainingItemsCount() }} more items available. Type to search.
                    </div>
                </mat-option>
            </mat-optgroup>
        </ng-container>

        <ng-container *ngIf="filteredOptions.length === 0 && !searching">
            <div class="no-results">
                No results found.
            </div>
        </ng-container>
    </mat-select>
</mat-form-field>
